import * as sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://9299b2f044a74852ac9a6215604760b9@o1315624.ingest.sentry.io/6567489',
  tracesSampleRate: 1.0,
});
