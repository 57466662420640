import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { memo, useMemo } from 'react';
import Head from 'next/head';

const getNames = (Church_Name) => ({
  '/': `Togather: ChurchOS for Small Groups, Member Health, Seamless Messaging, and more.`,
  '/pricing': `Togather: ChurchOS for Small Groups, Member Health, Seamless Messaging, and more.`,
  '/product-demo': `Togather: ChurchOS for Small Groups, Member Health, Seamless Messaging, and more.`,
  '/product-demo-videos': `Togather: ChurchOS for Small Groups, Member Health, Seamless Messaging, and more.`,
  '/get-started': `Togather: ChurchOS for Small Groups, Member Health, Seamless Messaging, and more.`,
  '/payment-succeed': `Togather: ChurchOS for Small Groups, Member Health, Seamless Messaging, and more.`,
  '/payment-failed': `Togather: ChurchOS for Small Groups, Member Health, Seamless Messaging, and more.`,
  '/our-story': `Togather: Our Story`,
  '/signin': `Togather - Sign In`,
  '/demo': 'Schedule a Demo | Togather',
  '/admin/home': `${Church_Name} - Dashboard`,
  '/admin/reports': `${Church_Name} - Reports`,
  '/admin/reports/new-dinner-party-sign-ups': `${Church_Name} - Reports - New Dinner Party Sign-ups`,
  '/admin/reports/total-attendance': `${Church_Name} - Reports - Total Attendance`,
  '/admin/reports/leader-attendance': `${Church_Name} - Reports - Leader Attendance Reports`,
  '/admin/members': `${Church_Name} - Members`,
  '/admin/members with member selected': `${Church_Name} - Members`,
  '/admin/groups': `${Church_Name} - Groups`,
  '/admin/inbox': `${Church_Name} - Inbox`,
  '/admin/service': `${Church_Name} - Service`,
  '/admin/homefeed': `${Church_Name} - Homefeed`,
  '/admin/homefeed/call-to-actions': `${Church_Name} - Homefeed - Call to Actions`,
  '/admin/homefeed/devotionals': `${Church_Name} - Homefeed - Devotionals`,
  '/admin/homefeed/photos': `${Church_Name} - Homefeed - Photos`,
  '/admin/homefeed/message': `${Church_Name} - Homefeed - Latest Message`,
  '/admin/homefeed/video': `${Church_Name} - Homefeed - Video`,
  '/admin/settings': `${Church_Name} - Settings`,
  '/signin/reset_password': `Togather - Reset Password`,
  '/signin/reset_password/code': `Togather - Reset Password`,
  '/signup': `Togather - Sign Up`,
  '/legal/copyright': `Togather - Copyright and DMCA Policy`,
  '/legal/terms': `Togather - Terms of Service`,
  '/legal/privacy': `Togather - Privacy Policy`,
});

const Title = () => {
  const Router = useRouter();
  const { title } = useSelector((state) => state.headers);
  const { userChurch } = useSelector(state => state.user);

  const newtitle = useMemo(() => {
    if (typeof window === 'undefined') {
      return '';
    }
    const currentChurchName =
      localStorage.getItem('current_church') !== 'undefined'
        ? JSON.parse(localStorage.getItem('current_church'))?.name
        : '';

    const churchName =
      Object.keys(userChurch)?.length > 0
        ? userChurch.church.name
        : currentChurchName;

    const names = getNames(churchName);
    const indexTitle = Object.keys(names).find(
      (name) => name === Router.pathname
    );
    if (title && names) {
      return `${names[indexTitle]} - ${title}`;
    } else {
      if (names[indexTitle]) {
        return `${names[indexTitle]}`;
      } else {
        return `${churchName ? churchName + ' - ' : ''}Togather`;
      }
    }
  }, [Router, title, userChurch]);

  return (
    <Head>
      <title>{newtitle}</title>
    </Head>
  );
};

export default memo(Title);
